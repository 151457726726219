define("discourse/plugins/discourse-group-addons/discourse/templates/connectors/user-profile-secondary/user-profile-card-group-flairs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="extended-groups">
    <dt class="groups">
      {{i18n
        "groups.title"
        count=this.model.groups.length
      }}
    </dt>
    {{#each @outletArgs.model.groups as |group|}}
      {{#unless group.automatic}}
        <span class="group">
          <LinkTo
            @route="group"
            @model={{group.name}}
            class="group-link"
          >{{group.name}}<span class="comma">,</span><
          /LinkTo>
        </span>
      {{/unless}}
    {{/each}}
  </div>
  
  */
  {
    "id": "8wNtHjLW",
    "block": "[[[10,0],[14,0,\"extended-groups\"],[12],[1,\"\\n  \"],[10,\"dt\"],[14,0,\"groups\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"groups.title\"],[[\"count\"],[[30,0,[\"model\",\"groups\",\"length\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"model\",\"groups\"]]],null]],null],null,[[[41,[51,[30,2,[\"automatic\"]]],[[[1,\"      \"],[10,1],[14,0,\"group\"],[12],[1,\"\\n        \"],[8,[39,7],[[24,0,\"group-link\"]],[[\"@route\",\"@model\"],[\"group\",[30,2,[\"name\"]]]],[[\"default\"],[[[[1,[30,2,[\"name\"]]],[10,1],[14,0,\"comma\"],[12],[1,\",\"],[13]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[2]],null],[13],[1,\"\\n\"]],[\"@outletArgs\",\"group\"],false,[\"div\",\"dt\",\"i18n\",\"each\",\"-track-array\",\"unless\",\"span\",\"link-to\"]]",
    "moduleName": "discourse/plugins/discourse-group-addons/discourse/templates/connectors/user-profile-secondary/user-profile-card-group-flairs.hbs",
    "isStrictMode": false
  });
});