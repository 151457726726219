define("discourse/plugins/discourse-group-addons/discourse/templates/connectors/user-card-post-names/user-card-group-flairs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="extended-groups">
    {{#each @outletArgs.user.available_flairs as |flair|}}
      {{#unless (isAvatarFlair flair @outletArgs.user.flair_group_id)}}
        <span class="group">
          <AvatarFlair
            @flairName={{flair.name}}
            @flairUrl={{flair.flair_url}}
            @flairBgColor={{flair.flair_bg_color}}
            @flairColor={{flair.flair_color}}
          />
        </span>
      {{/unless}}
    {{/each}}
  </div>
  
  */
  {
    "id": "J+ogO03N",
    "block": "[[[10,0],[14,0,\"extended-groups\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"user\",\"available_flairs\"]]],null]],null],null,[[[41,[51,[28,[37,4],[[30,2],[30,1,[\"user\",\"flair_group_id\"]]],null]],[[[1,\"      \"],[10,1],[14,0,\"group\"],[12],[1,\"\\n        \"],[8,[39,6],null,[[\"@flairName\",\"@flairUrl\",\"@flairBgColor\",\"@flairColor\"],[[30,2,[\"name\"]],[30,2,[\"flair_url\"]],[30,2,[\"flair_bg_color\"]],[30,2,[\"flair_color\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[2]],null],[13],[1,\"\\n\"]],[\"@outletArgs\",\"flair\"],false,[\"div\",\"each\",\"-track-array\",\"unless\",\"isAvatarFlair\",\"span\",\"avatar-flair\"]]",
    "moduleName": "discourse/plugins/discourse-group-addons/discourse/templates/connectors/user-card-post-names/user-card-group-flairs.hbs",
    "isStrictMode": false
  });
});